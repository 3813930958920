<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-xl text-primary font-bold">
                      {{ $t("review_and_submit") }}
                    </div>
                    <Divider></Divider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="col-12 md:col-12 sm:col-12 comp-grid">
            <div>
              <template v-if="!loading && item">
                <div class="grid">
                  <div v-if="applicationCompleted == false" class="col-12">
                    <div class="mb-2">
                      <div class="grid">
                        <div class="col-fixed hidden-xs hidden-sm" style="width: 120x">
                          <image-viewer :ratio="1 / 1" image-size="medium" :src="item.passport_picture"></image-viewer>
                        </div>
                        <div class="col">
                          <div class="text-2xl">
                            {{ $t("submit_application_for_review") }}
                          </div>
                          <div class="my-2 text-gray-500">
                            {{
                              $t(
                                "you_will_not_be_able_make_any_changes_after_submission"
                              )
                            }}
                          </div>
                          <Divider />
                          <div class="">
                            <Button :label="$t('submit_application')" class="p-button-primary px-5" :loading="saving"
                              @click="confirmSubmission()" />
                          </div>
                          <slot :saving="saving"></slot>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div v-if="applicationCompleted == true" class="grid justify-content-between">
                      <div class="col">
                        <div class="">
                          <div class="flex align-items-center">
                            <div class="col-fixed hidden-xs hidden-sm" style="width: 120x">
                              <image-viewer :ratio="1 / 1" image-size="medium"
                                :src="item.passport_picture"></image-viewer>
                            </div>
                            <div class="ml-2">
                              <div class="text-xl text-primary font-bold">
                                {{ $utils.upper(item.lastname) }}, {{
                                  $utils.capitalize(item.firstname) }} {{
                                  $utils.capitalize(item.othernames) }}
                              </div>
                              <div class="mt-1">{{ $utils.lower(item.email_address) }} • ({{
                                item.dial_code }}) {{ item.primary_telephone }}</div>
                              <div class="my-2">
                                <Button :label="$t('download_application_summary')" class="p-button-info px-5 py-2"
                                  :loading="saving" @click="downloadApplicationSummary()" />

                              </div>
                              <slot :saving="saving"></slot>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <Accordion>
                        <AccordionTab :header="$t('application_information')">
                          <div class="grid mt-2">
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">{{ $t("reference_number") }}:</div>
                                <div class="col font-bold">
                                  {{ item.application_ref }}
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">{{ $t("submission_date") }}:</div>
                                <div class="col font-bold">
                                  {{
                                    item.application_submission_date instanceof Date && !isNaN( item.application_submission_date) ? $utils.humanDatetime(item.application_submission_date) : $utils.humanDatetime($utils.dateTimeNow())
                                  }}
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">{{ $t("entry_level") }}:</div>
                                <div class="col font-bold">
                                  {{ item.entry_level }}
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">{{ $t("academic_year") }}:</div>
                                <div class="col font-bold">
                                  {{ item.academic_year }}
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">{{ $t("learning_institution") }}:</div>
                                <div class="col font-bold">
                                  {{ item.learning_institution }}
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">{{ $t("academic_entry_month") }}:</div>
                                <div class="col font-bold">
                                  {{ item.academic_month }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionTab>

                        <AccordionTab :header="$t('bio_information')">
                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-12 md:col-3 mb-4">
                                  {{ $t("applicant") }}:
                                </div>
                                <div class="col-12 md:col-9">
                                  <div class="grid">
                                    <div class="col italic text-gray-400">
                                      {{ $t("title") }}:
                                    </div>
                                    <div class="col">
                                      {{ item.title }}
                                    </div>
                                  </div>
                                  <div class="grid align-items-center">
                                    <Divider></Divider>
                                    <div class="col italic text-gray-400">
                                      {{ $t("first_name") }}:
                                    </div>
                                    <div class="col">
                                      {{ item.firstname }}
                                    </div>
                                  </div>
                                  <div class="grid align-items-center">
                                    <Divider></Divider>
                                    <div class="col italic text-gray-400">
                                      {{ $t("last_name") }}:
                                    </div>
                                    <div class="col">
                                      {{ item.lastname }}
                                    </div>
                                  </div>
                                  <div class="grid align-items-center">
                                    <Divider></Divider>
                                    <div class="col italic text-gray-400">
                                      {{ $t("other_names") }}:
                                    </div>
                                    <div class="col">
                                      {{ item.othernames }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <Divider></Divider>
                              <div class="grid align-items-center">
                                <div class="col-3">
                                  {{ $t("day_of_birth") }}:
                                </div>
                                <div class="col">
                                  {{ item.dob_day }} {{ item.dob_month }}
                                  {{ item.dob_year }}
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <Divider></Divider>
                              <div class="grid align-items-center">
                                <div class="col-3">
                                  {{ $t("country_of_origin") }}:
                                </div>
                                <div class="col">
                                  {{ item.country }}
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <Divider></Divider>
                              <div class="grid align-items-center">
                                <div class="col-3">{{ $t("home_town") }}:</div>
                                <div class="col">
                                  {{ item.hometown }}
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <Divider></Divider>
                              <div class="grid align-items-center">
                                <div class="col-3">
                                  {{ $t("home_town_region") }}:
                                </div>
                                <div class="col">
                                  {{ item.hometown_region }}
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <Divider></Divider>
                              <div class="grid align-items-center">
                                <div class="col-12 md:col-3">
                                  {{ $t("religious_denomination") }}:
                                </div>
                                <div class="col-12 md:col-9">
                                  {{ item.religious_denomination }}
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <Divider></Divider>
                              <div class="grid align-items-center">
                                <div class="col-12 md:col-3">
                                  {{ $t("religious_denomination") }} ({{
                                    $t("other")
                                  }}):
                                </div>
                                <div class="col-12 md:col-9">
                                  {{ item.religious_denomination_other }}
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <Divider></Divider>
                              <div class="grid align-items-center">
                                <div class="col-3">
                                  {{ $t("email_address") }}:
                                </div>
                                <div class="col">
                                  {{ item.email_address }}
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <Divider></Divider>
                              <div class="grid align-items-center">
                                <div class="col-3">
                                  {{ $t("marital_status") }}:
                                </div>
                                <div class="col">
                                  {{ item.marital_status }}
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <Divider></Divider>
                              <div class="grid align-items-center">
                                <div class="col-3">{{ $t("gender") }}:</div>
                                <div class="col">
                                  {{ item.gender }}
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <Divider></Divider>
                              <div class="grid align-items-center">
                                <div class="col-3">
                                  {{ $t("primary_contact") }}:
                                </div>
                                <div class="col">
                                  {{ item.dial_code }}
                                  {{ item.primary_telephone }}
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <Divider></Divider>
                              <div class="grid align-items-center">
                                <div class="col-12 md:col-3">
                                  {{ $t("address_of_applicant") }}:
                                </div>
                                <div class="col-12 md:col-9">
                                  <div class="grid align-items-center">
                                    <div class="col italic text-gray-400">
                                      {{ $t("address_line_1") }}:
                                    </div>
                                    <div class="col">
                                      {{ item.address_line1 }}
                                    </div>
                                  </div>
                                  <div class="grid align-items-center">
                                    <Divider></Divider>
                                    <div class="col italic text-gray-400">
                                      {{ $t("address_line_2") }}:
                                    </div>
                                    <div class="col">
                                      {{ item.address_line2 }}
                                    </div>
                                  </div>
                                  <div class="grid align-items-center">
                                    <Divider></Divider>
                                    <div class="col italic text-gray-400">
                                      {{ $t("address_line_3") }}:
                                    </div>
                                    <div class="col">
                                      {{ item.address_line3 }}
                                    </div>
                                  </div>
                                  <div class="grid align-items-center">
                                    <Divider></Divider>
                                    <div class="col italic text-gray-400">
                                      {{ $t("address_line_4") }}:
                                    </div>
                                    <div class="col">
                                      {{ item.address_line4 }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <Divider></Divider>
                              <div class="grid align-items-center">
                                <div class="col-3">
                                  {{ $t("digital_address") }}:
                                </div>
                                <div class="col">
                                  {{ item.digital_address }}
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <Divider></Divider>
                              <div class="grid align-items-center">
                                <div class="col-3">
                                  {{ $t("street_address") }}:
                                </div>
                                <div class="col">
                                  {{ item.street_address }}
                                </div>
                              </div>
                            </div>

                            <Divider></Divider>

                            <div class="col-12 md:col-12">
                              <div class="grid align-items-center">
                                <div class="col-12 md:col-3">
                                  {{
                                    $t("disability_special_needs_information")
                                  }}:
                                </div>
                                <div class="col-12 md:col-9">
                                  <div class="grid align-items-center">
                                    <div class="col italic text-gray-400">
                                      {{ $t("do_you_have_any_disability") }}:
                                    </div>
                                    <div class="col">
                                      {{ item.is_disable }}
                                    </div>
                                  </div>
                                  <div class="grid align-items-center">
                                    <Divider></Divider>
                                    <div class="col italic text-gray-400">
                                      {{ $t("disability") }}:
                                    </div>
                                    <div class="col">
                                      {{ item.disability }}
                                    </div>
                                  </div>
                                  <div class="grid align-items-center">
                                    <Divider></Divider>
                                    <div class="col italic text-gray-400">
                                      {{ $t("disability") }} ({{
                                        $t("other")
                                      }}):
                                    </div>
                                    <div class="col">
                                      {{ item.disability_other }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Divider></Divider>
                            </div>

                            <div class="col-12 md:col-12">
                              <div class="grid align-items-center">
                                <div class="col-12 md:col-3">
                                  {{ $t("identification") }}:
                                </div>
                                <div class="col-12 md:col-9">
                                  <div class="grid align-items-center">
                                    <div class="col italic text-gray-400">
                                      {{ $t("national_id_type") }}:
                                    </div>
                                    <div class="col">
                                      {{ item.national_id_type }}
                                    </div>
                                  </div>
                                  <div class="grid align-items-center">
                                    <Divider></Divider>
                                    <div class="col italic text-gray-400">
                                      {{ $t("national_id_number") }}:
                                    </div>
                                    <div class="col">
                                      {{ item.national_id_number }}
                                    </div>
                                  </div>
                                  <div class="grid align-items-center">
                                    <Divider></Divider>
                                    <div class="col italic text-gray-400">
                                      {{ $t("scanned_national_id_card") }}:
                                    </div>
                                    <div class="col">
                                      <file-viewer :model-value="item.scanned_national_id_card"></file-viewer>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionTab>

                        <AccordionTab :header="$t('working_experience')">
                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col italic text-gray-400">
                                  {{
                                    $t(
                                      "how_many_years_of_working_experience_do_you_have"
                                    )
                                  }}
                                </div>
                                <div class="col">
                                  {{ item.has_working_experience }}
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12" v-if="item.working_experience">
                              <div v-for="(definition, index) in JSON.parse(
                                item.working_experience
                              )" :key="index">
                                <Divider></Divider>
                                <div class="col-12 md:col-12">
                                  <div class="grid">
                                    <div class="col-12 md:col-2 text-purple-500 mb-2">
                                      <Chip :label="index + 1"></Chip>
                                    </div>
                                    <div class="col-12 md:col-9">
                                      <div class="grid">
                                        <div class="col italic text-gray-400">
                                          {{ $t("employer") }}:
                                        </div>
                                        <div class="col">
                                          {{ definition.employer }}
                                        </div>
                                      </div>
                                      <div class="grid align-items-center">
                                        <Divider></Divider>
                                        <div class="col italic text-gray-400">
                                          {{ $t("position") }}:
                                        </div>
                                        <div class="col">
                                          {{ definition.position }}
                                        </div>
                                      </div>
                                      <div class="grid align-items-center">
                                        <Divider></Divider>
                                        <div class="col italic text-gray-400">
                                          {{ $t("from") }}:
                                        </div>
                                        <div class="col">
                                          {{ definition.from }}
                                        </div>
                                      </div>
                                      <div class="grid align-items-center">
                                        <Divider></Divider>
                                        <div class="col italic text-gray-400">
                                          {{ $t("to") }}:
                                        </div>
                                        <div class="col">
                                          {{ definition.to }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12" v-if="item.curriculum_vitae">
                              <div class="grid">
                                <Divider></Divider>
                                <div class="col italic text-gray-400">
                                  {{ $t("curriculum_vitae") }}:
                                </div>
                                <div class="col">
                                  <file-viewer :model-value="item.curriculum_vitae"></file-viewer>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionTab>

                        <AccordionTab :header="$t('academic_history')">
                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col italic text-gray-400">
                                  {{
                                    $t(
                                      "have_you_attended_any_tertiary_institution"
                                    )
                                  }}
                                </div>
                                <div class="col">
                                  {{ item.has_attended_university }}
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12" v-if="item.institutions_attended">
                              <div v-for="(definition, index) in JSON.parse(
                                item.institutions_attended
                              )" :key="index">
                                <Divider></Divider>
                                <div class="col-12 md:col-12">
                                  <div class="grid">
                                    <div class="col-12 md:col-2 text-purple-500 mb-2">
                                      <Chip :label="index + 1"></Chip>
                                    </div>
                                    <div class="col-12 md:col-9">
                                      <div class="grid">
                                        <div class="col italic text-gray-400">
                                          {{ $t("name_of_institution") }}:
                                        </div>
                                        <div class="col">
                                          {{ definition.name_of_institution }}
                                        </div>
                                      </div>
                                      <div class="grid align-items-center">
                                        <Divider></Divider>
                                        <div class="col italic text-gray-400">
                                          {{ $t("from") }}:
                                        </div>
                                        <div class="col">
                                          {{ definition.from }}
                                        </div>
                                      </div>
                                      <div class="grid align-items-center">
                                        <Divider></Divider>
                                        <div class="col italic text-gray-400">
                                          {{ $t("to") }}:
                                        </div>
                                        <div class="col">
                                          {{ definition.to }}
                                        </div>
                                      </div>
                                      <div class="grid align-items-center">
                                        <Divider></Divider>
                                        <div class="col italic text-gray-400">
                                          {{ $t("tertiary_programme") }}:
                                        </div>
                                        <div class="col">
                                          {{ definition.programme }}
                                        </div>
                                      </div>
                                      <div class="grid align-items-center">
                                        <Divider></Divider>
                                        <div class="col italic text-gray-400">
                                          {{ $t("degree_awarded") }}:
                                        </div>
                                        <div class="col">
                                          {{ definition.degree_awarded }}
                                        </div>
                                      </div>
                                      <div class="grid align-items-center">
                                        <Divider></Divider>
                                        <div class="col italic text-gray-400">
                                          {{ $t("degree_class") }}:
                                        </div>
                                        <div class="col">
                                          {{ definition.degree_class }}
                                        </div>
                                      </div>
                                      <div v-if="definition.certificate_url" class="grid align-items-center">
                                        <Divider></Divider>
                                        <div class="col italic text-gray-400">
                                          {{ $t("Certificate") }}:
                                        </div>
                                        <div class="col">
                                          <file-viewer :model-value="definition.certificate_url"></file-viewer>
                                        </div>
                                      </div>
                                      <div v-if="definition.transcript" class="grid align-items-center">
                                        <Divider></Divider>
                                        <div class="col italic text-gray-400">
                                          {{ $t("Transcript") }}:
                                        </div>
                                        <div class="col">
                                          <file-viewer :model-value="definition.transcript"></file-viewer>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <Divider></Divider>
                                <div class="col italic text-gray-400">
                                  {{ $t("research_and_professional_experience") }}:
                                </div>
                                <div class="col">
                                  <file-viewer :model-value="item.research_proposal"></file-viewer>
                                </div>
                              </div>
                              <div class="grid">
                                <Divider></Divider>
                                <div class="col italic text-gray-400">
                                  {{ $t("statement_of_purpose") }}:
                                </div>
                                <div class="col">
                                  <file-viewer :model-value="item.statement_of_purpose"></file-viewer>
                                </div>
                              </div>
                              <div class="grid" v-if="item.publications">
                                <Divider></Divider>
                                <div class="col italic text-gray-400">
                                  {{ $t("Publications") }}:
                                </div>
                                <div class="col">
                                  <file-viewer :model-value="item.publications"></file-viewer>
                                </div>
                              </div>
                            </div>

                          </div>
                        </AccordionTab>

                        <AccordionTab :header="$t('selection_of_programme')">
                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <div class="grid align-items-center">
                                <div class="col-3 italic text-gray-400">
                                  {{ $t("programme") }}:
                                </div>
                                <div class="col">
                                  <div> {{ item.first_programme_title }}</div>
                                  <div class="text-sm"><span class="text-purple-400">{{
                                    $t("in_collaboration_with")
                                  }}</span>
                                    {{ item.first_programme_collaboration_with }}
                                  </div>
                                </div>
                              </div>
                              <div class="grid">
                                <Divider></Divider>
                                <div class="col-3 italic text-gray-400">
                                  {{ $t("campus") }}:
                                </div>
                                <div class="col">
                                  {{ item.first_programme_campus }}
                                </div>
                              </div>
                              <div class="grid">
                                <Divider></Divider>
                                <div class="col-3 italic text-gray-400">
                                  {{ $t("duration") }}:
                                </div>
                                <div class="col">
                                  {{ item.first_programme_duration }}
                                </div>
                              </div>
                              <div class="grid">
                                <Divider></Divider>
                                <div class="col-3 italic text-gray-400">
                                  {{ $t("session") }}:
                                </div>
                                <div class="col">
                                  {{ item.first_programme_session }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionTab>

                        <AccordionTab :header="$t('funding_or_scholarship')">
                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="md:col-3">
                                  {{ $t("Sponsorship") }}
                                </div>
                                <div class="col font-bold">
                                  {{ item.sponsor }}
                                </div>
                              </div>
                              <div class="grid align-items-center">
                                <Divider></Divider>
                                <div class="col italic text-gray-400">
                                  {{ $t("name_of_sponsor") }}:
                                </div>
                                <div class="col">
                                  {{ item.sponsor_name }}
                                </div>
                              </div>
                              <div class="grid align-items-center">
                                <Divider></Divider>
                                <div class="col italic text-gray-400">
                                  {{ $t("full_address_of_sponsor") }}:
                                </div>
                                <div class="col">
                                  {{ item.sponsor_address }}
                                </div>
                              </div>
                              <div class="grid align-items-center">
                                <Divider></Divider>
                                <div class="col italic text-gray-400">
                                  {{ $t("sponsor_telephone") }}:
                                </div>
                                <div class="col">
                                  {{ item.sponsor_telephone_dial_code }} {{
                                    item.sponsor_telephone }}
                                </div>
                              </div>
                              <div class="grid align-items-center">
                                <Divider></Divider>
                                <div class="col italic text-gray-400">
                                  {{ $t("sponsor_email") }}:
                                </div>
                                <div class="col">
                                  {{ item.sponsor_email }}
                                </div>
                              </div>

                            </div>
                          </div>
                        </AccordionTab>

                        <AccordionTab :header="$t('payment')">
                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <view-payments-page is-sub-page ref="viewPaymentsPage" v-if="ready">
                              </view-payments-page>
                            </div>
                          </div>
                        </AccordionTab>

                        <AccordionTab :header="$t('documents')">
                          <div class="">
                            <div class="grid mt-2">
                              <div class="col-12 md:col-12">
                                <div v-if="item.scanned_national_id_card" class="grid">
                                  <div class="col-4">{{ $t("scanned_national_id_card") }}
                                    ({{ item.national_id_type }}):</div>
                                  <div class="col font-bold">
                                    <file-viewer :model-value="item.scanned_national_id_card"></file-viewer>
                                  </div>
                                </div>
                                <div v-if="item.gtec_evaluation" class="grid">
                                  <div class="col-4">{{ $t("gtec_evaluation") }}:</div>
                                  <div class="col font-bold">
                                    <file-viewer :model-value="item.gtec_evaluation"></file-viewer>
                                  </div>
                                </div>
                                <div v-if="item.previous_qualifications" class="grid">
                                  <div class="col-4">{{ $t("previous_qualifications") }}:
                                  </div>
                                  <div class="col font-bold">
                                    <file-viewer :model-value="item.previous_qualifications"></file-viewer>
                                  </div>
                                </div>
                                <div v-if="item.transcript" class="grid">
                                  <div class="col-4">{{ $t("transcript") }}:</div>
                                  <div class="col font-bold">
                                    <file-viewer :model-value="item.transcript"></file-viewer>
                                  </div>
                                </div>
                                <div v-if="item.english_proficiency" class="grid">
                                  <div class="col-4">{{ $t("english_proficiency") }}:
                                  </div>
                                  <div class="col font-bold">
                                    <file-viewer :model-value="item.english_proficiency"></file-viewer>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionTab>

                        <AccordionTab :header="$t('recommendation_letters')">
                          <div class="">
                            <div class="grid mt-2">
                              <div class="col-12 md:col-12" v-if="item.recommendation_letters">
                                <div v-for="(definition, index) in JSON.parse(
                                  item.recommendation_letters
                                )" :key="index">
                                  <div class="col-12 md:col-12">
                                    <div class="grid">
                                      <div class="col-12 md:col-2 text-purple-500 mb-2">
                                        <Chip :label="index + 1"></Chip>
                                      </div>
                                      <div class="col-12 md:col-10">
                                        <div class="grid">
                                          <div class="col italic text-gray-400">
                                            {{ $t("name") }}:
                                          </div>
                                          <div class="col">
                                            {{ definition.name }}
                                          </div>
                                        </div>
                                        <div class="grid align-items-center">
                                          <Divider></Divider>
                                          <div class="col italic text-gray-400">
                                            {{ $t("position") }}:
                                          </div>
                                          <div class="col">
                                            {{ definition.position }}
                                          </div>
                                        </div>
                                        <div class="grid align-items-center">
                                          <Divider></Divider>
                                          <div class="col italic text-gray-400">
                                            {{ $t("institution") }}:
                                          </div>
                                          <div class="col">
                                            {{ definition.institution }}
                                          </div>
                                        </div>
                                        <div class="grid align-items-center">
                                          <Divider></Divider>
                                          <div class="col italic text-gray-400">
                                            {{ $t("email") }}:
                                          </div>
                                          <div class="col">
                                            {{ definition.email }}
                                          </div>
                                        </div>
                                        <div class="grid align-items-center">
                                          <Divider></Divider>
                                          <div class="col italic text-gray-400">
                                            {{ $t("phone") }}:
                                          </div>
                                          <div class="col">
                                            {{ definition.phone }}
                                          </div>
                                        </div>
                                        <Divider></Divider>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionTab>

                      </Accordion>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="p-3 text-center">
                  <ProgressSpinner style="width: 50px; height: 50px" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { mapActions, mapGetters, mapState } from "vuex";
import { PageMixin } from "../../mixins/page.js";
import { ViewPageMixin } from "../../mixins/viewpage.js";
import { i18n } from "../../services/i18n.js";
import viewPaymentsPage from "../payments/view.vue";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "summarydataApplicantsPage",
  components: { viewPaymentsPage },
  mixins: [PageMixin, ViewPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
    idName: {
      type: String,
      default: "applicant_id",
    },
    routeName: {
      type: String,
      default: "applicantssummarydata",
    },
    pagePath: {
      type: String,
      default: "applicants/summarydata",
    },
    apiPath: {
      type: String,
      default: "applicants/summarydata",
    },
    pageIndex: { type: Number, default: 7 },
    applicationCompleted: { type: Boolean, default: false },
  },
  data() {
    return {
      item: {
        default: {},
      },
      saving: false
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return this.$t("review_and_submit");
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["applicants/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("applicants/setCurrentRecord", value);
      },
    },
  },
  meta() {
    return {
      title: this.pageTitle,
    };
  },
  methods: {
    ...mapActions("applicants", ["fetchRecord", "submitApplication"]),
    confirmSubmission() {
      this.$confirm.require({
        message: this.$t("are_you_sure_you_want_to_submit_this_application"),
        header: this.$t("confirmation"),
        icon: "pi pi-info-circle",
        accept: () => {
          this.startApplicationSubmission();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    async startApplicationSubmission() {
      this.saving = true;
      let url = this.apiUrl;
      let payload = {};
      let data = { url, payload };
      this.submitApplication(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(this.$t("application_has_been_submitted_successfully"));
          this.closeDialogs(); // close page dialog that if opened
          this.navigateTo(`results`);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    async downloadApplicationSummary() {
      this.saving = true;
      let url = this.apiUrl;
      let payload = { download: "pdf" };
      let fileName = "application_summary_export_" + Math.floor(new Date() / 1000) + ".pdf";
      this.$toast.add({ severity: "success", summary: "Requesting pdf file...", detail: "File will be downloaded soon" });
      this.$api.downloadpost(url, payload).then((response) => {
        this.saving = false;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.$toast.removeAllGroups();
      },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        });
    },
  },
  watch: {
    $route(to, from) {
      //only fetch data when navigated to this page
      if (to.name == this.routeName) {
        //this.load();
      }
    },
  },
  async mounted() { },
  async created() { },
};
</script>
<style scoped></style>